import { Stack } from "@mui/material"
import { useState } from "react"
import { useAppSelector } from "../../app/hooks"
import { selectDialogOpen } from "../../common/uiSlice"
import AlertDialog, { AlertDialogProps } from "../../components/AlertDialog"
import { ForecastView } from "./ForecastView"

const MainPage = () => {

    const dialogOpen = useAppSelector(selectDialogOpen)
    const [dialogProps, setDialogProps] = useState<AlertDialogProps>({ title: '', message: '' })

    return (
        <Stack>
            <ForecastView setDialogProps={setDialogProps} />
            {/* <Divider sx={{ margin: '1rem' }} />
            <ScenarioView setDialogProps={setDialogProps} /> */}
            { dialogOpen ? <AlertDialog { ...dialogProps }  /> : null }
        </Stack>
    )
}

export default MainPage