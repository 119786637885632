import { SpatialDataWithGeometries } from "@backend/types";
import { BoundingBoxesType, SpatialDataType } from "../../types";
import { populaApi } from "./apiSlice";

export const areaApiSlice = populaApi.injectEndpoints({
    endpoints: (builder) => ({
        getBbox: builder.query<BoundingBoxesType,void>({
            query: () => `spatial/bbox`,
        }),
        getSpatialData: builder.query<SpatialDataWithGeometries, { spatialId: number }>({
            query: ({ spatialId }) => `spatial/spatial-data/${spatialId}`,
        }),
        getAvailableSpatialData: builder.query<SpatialDataType[], void>({
            query: () => `spatial/available-data`,
        }),
    })
})

export const {
    useGetBboxQuery,
    useGetAvailableSpatialDataQuery,
    useLazyGetSpatialDataQuery,
} = areaApiSlice