import { YearAndValueType } from "../types"
import { ParameterLineChart } from "./charts/LineCharts"

type ProjectionParametersDiagramType = {
    label: string,
    data: YearAndValueType[],
    history: YearAndValueType[],
    roundTo?: number,
}
export const ProjectionParametersDiagram = ({ data, history, label, roundTo }:ProjectionParametersDiagramType) => {

    return (
        (
        data &&
        <ParameterLineChart
            label={label}
            data={data}
            history={history}
            dataKeys={[ 'value' ]}
            roundTo={roundTo}
        />
        ) || null
    )
}
