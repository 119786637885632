import { FertilityForecastType, ForecastType, MigrationForecastType, MortalityForecastType, Municipality, ParameterQueryType, SupportedParameterMethodsType, User } from "../../types"
import { populaApi } from "./apiSlice"

export const parameterApiSlice = populaApi.injectEndpoints({
    endpoints: (builder) => ({
        getSupportedParameterMethods: builder.query<SupportedParameterMethodsType, void>({
            query: () => `forecast/supported-parameter-methods`,
        }),
        getUserData: builder.query<User, void>({
            query: () => `user`,
        }),
        getMunicipalities: builder.query<Municipality[], void>({
            query: () => `municipality`,
        }),
        getHistoryYears: builder.query<number[], void>({
            query: () =>
                `history/history-years`,
        }),
        getMigrationHistory: builder.query<MigrationForecastType[], string>({
            query: () =>
                `history/migration`,
            providesTags: ['MigrationHistory'],
        }),
        getFertilityHistory: builder.query<FertilityForecastType, string>({
            query: () =>
                `history/fertility`,
            providesTags: ['FertilityHistory'],
        }),

        getMigrationPreviewForecastByType: builder.query<MigrationForecastType, ParameterQueryType>({
            query: ({ parameterName, fromYear, toYear, forecastMethod }) =>
                `forecast/migration/${parameterName}/${fromYear}/${toYear}/${forecastMethod?.method}?historyYears=${forecastMethod?.historyYears}`,
            providesTags: ['MigrationForecastPreview'],
        }),

        // TODO combine these into one query
        getImmigrationForecast: builder.query<MigrationForecastType, ParameterQueryType>({
            query: ({ fromYear, toYear, forecastMethod }) =>
                `forecast/migration/immigration/${fromYear}/${toYear}/${forecastMethod?.method}?historyYears=${forecastMethod?.historyYears}`,
            providesTags: ['ImmigrationForecast'],
        }),
        getEmigrationForecast: builder.query<MigrationForecastType, ParameterQueryType>({
            query: ({ fromYear, toYear, forecastMethod }) =>
                `forecast/migration/emigration/${fromYear}/${toYear}/${forecastMethod?.method}?historyYears=${forecastMethod?.historyYears}`,
            providesTags: ['EmigrationForecast'],
        }),
        getInterMunicipalMigrationInForecast: builder.query<MigrationForecastType, ParameterQueryType>({
            query: ({ fromYear, toYear, forecastMethod }) =>
                `forecast/migration/interMunicipalMigrationIn/${fromYear}/${toYear}/${forecastMethod?.method}?historyYears=${forecastMethod?.historyYears}`,
            providesTags: ['InterMunicipalMigrationInForecast'],
        }),
        getInterMunicipalMigrationOutForecast: builder.query<MigrationForecastType, ParameterQueryType>({
            query: ({ fromYear, toYear, forecastMethod }) =>
                `forecast/migration/interMunicipalMigrationOut/${fromYear}/${toYear}/${forecastMethod?.method}?historyYears=${forecastMethod?.historyYears}`,
            providesTags: ['InterMunicipalMigrationOutForecast'],
        }),
        // TODO combine these into one query

        getMortalityForecast: builder.query<MortalityForecastType, ParameterQueryType>({
            query: ({ fromYear, toYear, forecastMethod }) =>
                `forecast/mortality/${fromYear}/${toYear}/${forecastMethod?.method}`,
            providesTags: ['MortalityForecast'],
        }),
        getFertilityForecast: builder.query<FertilityForecastType, ParameterQueryType>({
            query: ({ fromYear, toYear, forecastMethod }) => {
                return `forecast/fertility/${fromYear}/${toYear}/${forecastMethod?.method}?historyYears=${forecastMethod?.historyYears}`
            },
            providesTags: ['FertilityForecast'],
        }),
        updateMortalityForecast: builder.mutation<MortalityForecastType, ParameterQueryType>({
            query: ({ fromYear, toYear, forecastMethod }) =>
                `forecast/mortality/${fromYear}/${toYear}/${forecastMethod}`,
            invalidatesTags: ['MunicipalSummary', 'MortalityForecast'],
        }),
        updateFertilityForecast: builder.mutation<FertilityForecastType, { parameter: ParameterQueryType, forecast: ForecastType }>({
            query: ({ forecast, parameter: { forecastMethod } }) => ({
                url: `forecast/fertility/${forecast.id}/${forecastMethod}`,
                method: 'PATCH',
            }),
            invalidatesTags: (_, __, { forecast }) => [ { type: 'Forecasts', id: forecast.id }, 'MunicipalData', 'AreaData', 'MunicipalSummaryData', 'AreaSummaryData' ],
        }),
    }),
})

export const selectParameterMethods = parameterApiSlice.endpoints.getSupportedParameterMethods.select()

export const {
    useGetSupportedParameterMethodsQuery,
    useGetHistoryYearsQuery,
    useGetMigrationHistoryQuery,
    useGetFertilityHistoryQuery,
    useGetMigrationPreviewForecastByTypeQuery,
    useUpdateMortalityForecastMutation,
    useUpdateFertilityForecastMutation,
    useGetImmigrationForecastQuery,
    useGetEmigrationForecastQuery,
    useGetInterMunicipalMigrationInForecastQuery,
    useGetInterMunicipalMigrationOutForecastQuery,
    useGetMortalityForecastQuery,
    useGetFertilityForecastQuery,

    useGetUserDataQuery,
    useGetMunicipalitiesQuery
} = parameterApiSlice

