import * as turf from '@turf/turf';
import mapboxgl, { LngLatBoundsLike, Map } from 'mapbox-gl';
import { useEffect, useMemo, useRef } from "react";
import { BoundingBoxesType, SpatialDataWithGeometries } from '../types';
import { addAreaData, setAreaData } from './mapFunctions';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

type PopulaMapProps = {
    bbox: BoundingBoxesType,
    geometries: SpatialDataWithGeometries | undefined,
    chosenArea: string | null
}

const BBOX_FINLAND = [
    [20.6455928891, 59.846373196],
    [31.5160921567, 70.1641930203]
] as LngLatBoundsLike;

const PopulaMap = ({ bbox, geometries, chosenArea }: PopulaMapProps) => {

    const mapContainer = useRef<any>(null);
    const map = useRef<Map>();

    const bufferedBbox: [[number, number], [number, number]] = useMemo(() => {
        const { buffered_bbox: bb } = bbox
        return [[bb.max_lon, bb.max_lat], [bb.min_lon, bb.min_lat]]
    }, [bbox])

    useEffect(() => {

        if (!map.current) return;

        // FIXME why do we need to cast to any?
        if (chosenArea) {
            const { geometry } = geometries!.geojson.find((g: any) => g.properties.name === chosenArea)!
            map.current.fitBounds(turf.bbox(geometry) as any, { padding: 5 })
        } else {
            map.current.fitBounds(bufferedBbox)
        }
    }, [chosenArea])

    useEffect(() => {

        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current!,
            style: 'mapbox://styles/tuomaspiippo/ckw28ns851jtg15p8xc55x1km',
            accessToken: 'pk.eyJ1IjoidHVvbWFzcGlpcHBvIiwiYSI6ImNrdzBmMmpjMzBmM3cyeHBxa3g5c24zZDIifQ._71g8ijeVtAcK0RxfaijHg',
            bounds: BBOX_FINLAND
        });

        const { current: currentMap } = map

        currentMap.on('load', () => {

            currentMap?.fitBounds(bufferedBbox)

            currentMap?.once('zoomend', () => {
                currentMap?.setMaxBounds(currentMap?.getBounds()!)
            })
        });
    }, [])

    useEffect(() => {

        const { current: currentMap } = map

        if (!geometries || !currentMap) return;

        const { geojson } = geometries

        if (!geojson) return;

        if (currentMap.getSource('geometries')) {
            setAreaData(currentMap, geojson)
        } else {
            // Fix the Style is not done loading error
            currentMap.on('load', () => {
                addAreaData(currentMap, geojson)
            })
        }
    }, [geometries])

    return (
        <div ref={mapContainer} className="map-container"></div>
    )
}

export default PopulaMap