import { Box, Button, Drawer, DrawerProps } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { closeDrawer, selectDrawerOpen } from "../common/uiSlice"

export const DIYDrawer: React.FC<DrawerProps> = ({ children }) => {

    const dispatch = useAppDispatch()
    const drawerOpen = useAppSelector(selectDrawerOpen)

    return drawerOpen && children ? (
        <Box
            sx={{
                zIndex: 'auto',
                overflowY: 'auto',
                position: 'fixed',
                top: 0,
                right: 0,
                width: '35%',
                height: '100%',
                backgroundColor: 'white',
                padding: '1rem',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                opacity: drawerOpen ? 1 : 0,
                transition: 'opacity 0.3s ease-in',
            }}
        >
            <Button
                variant="contained"
                color="primary"
                onClick={() => dispatch(closeDrawer())}>
                <span className="material-icons-outlined">close</span>
            </Button>
            <Box>
                {drawerOpen ? children : null}
            </Box>
        </Box>
    ) : null
}

const NonModalDrawer = ({ children }: DrawerProps) => {

    const drawerOpen = useAppSelector(selectDrawerOpen)

    return (
        <Drawer
            anchor="right"
            open={drawerOpen}
            hideBackdrop={true}
            PaperProps={{
                sx: {
                    width: '30%',
                    padding: '1rem',
                }
            }}
        >
            {children}
        </Drawer>
    )
}

const DrawerComponent = ({ children }: DrawerProps) => {

    const drawerOpen = useAppSelector(selectDrawerOpen)

    return (
        <Drawer
            anchor="right"
            open={drawerOpen}
            hideBackdrop={false}
            PaperProps={{
                sx: {
                    width: '30%',
                    padding: '1rem',
                }
            }}
        >
            {children}
        </Drawer>
    )
}

export default DrawerComponent