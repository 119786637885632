export const msalConfig = {
    auth: {
        clientId: "4dd18068-4a4d-4735-b44a-fddecfa9c3c1",
        authority: "https://login.microsoftonline.com/543cc610-b1b2-489c-9680-3fc0cb91232b", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [ "User.Read" ]
};