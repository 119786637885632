import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../app/hooks"
import { closeDrawer } from "../../common/uiSlice"

type MethodOptionsPropsType = {
    valueLabel: string,
    options: {
        label: string,
        value: string,
        disabled?: boolean,
    }[],
}

const MethodOptions = ({ valueLabel, options }:MethodOptionsPropsType) => {

    return (
        <Box sx={{ paddingBottom: '1rem' }}>
        <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">{valueLabel}</FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={null}
                onChange={() => {}}
            >
                {
                options.map((option, key) => (
                    <FormControlLabel key={key} value={option.value} disabled={option.disabled} control={<Radio />} label={option.label} />
                ))
                }
            </RadioGroup>
        </FormControl>
        </Box>
    )
}

const AreaTotalsView = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const options = [{
        label: t('forecastMethod.moving_average'),
        value: 'mean',
    }, {
        label: t('forecastMethod.latest'),
        value: 'latest',
    },{
        label: t('forecastMethod.median'),
        value: 'median',
    }]

    const disabledProps = [{
        'label': t('forecastMethod.tkForecast'),
        'value': 'disabled',
        disabled: true,
    }]

    return (
        <Stack>
            <h3>{t('areaView.totalsView.title')}</h3>
            <hr />
            <MethodOptions valueLabel={t('projectionParameter.intraMunicipalMigrationIn')} options={options} />
            <MethodOptions valueLabel={t('projectionParameter.intraMunicipalMigrationOut')} options={options} />
            <MethodOptions valueLabel={t('projectionParameter.interMunicipalMigrationIn')} options={options} />
            <MethodOptions valueLabel={t('projectionParameter.interMunicipalMigrationOut')} options={options} />
            <MethodOptions valueLabel={t('projectionParameter.immigration')} options={options} />
            <MethodOptions valueLabel={t('projectionParameter.emigration')} options={options} />
            <MethodOptions valueLabel={t('projectionParameter.fertilityForecast')} options={disabledProps} />
            <MethodOptions valueLabel={t('projectionParameter.mortalityForecast')} options={disabledProps} />
            <Stack direction="row" spacing={2} width='100%' alignItems={'right'}>
                <Button variant="outlined" onClick={() => dispatch(closeDrawer())}>{t('general.cancel')}</Button>
                <Button variant="contained" onClick={() => { }}>{t('general.apply')}</Button>
            </Stack>
        </Stack>
    )
}

export default AreaTotalsView