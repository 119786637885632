import { Stack } from "@mui/material"
import { GridColDef, GridRowsProp, useGridApiRef, useKeepGroupedColumnsHidden } from "@mui/x-data-grid-premium"
import { useMemo } from "react"
import { useAppSelector } from "../../app/hooks"
import { DataGridComponent } from "../../components/Tables"
import { AreaSummaryType, NewBuildingsProductionPerAreaType } from "../../types"
import { selectCurrentForecastId } from "../apis/appSlice"
import { useGetNewBuildingsProductionProjectionQuery, useGetProductionForecastQuery } from "../apis/areaSlice"
import BuildingProductionParameterForm from "./BuildingProductionParameterForm"

const buildingTypes = {
    '1': 'Omakotitalo',
    '2': 'Rivitalo',
    '3': 'Kerrostalo',
}

const migrationForecastColumnDefs: GridColDef[] = [
    { field: 'areaCode' },
    { field: 'areaName', headerName: 'Alue', width: 250 },
    {
        field: 'buildingType',
        headerName: 'Rakennustyyppi',
        width: 120,
        groupable: false,
        valueFormatter: ({ value }) => buildingTypes[value as keyof typeof buildingTypes],
    },
]

const generateColumnDefs = (summary: AreaSummaryType): GridColDef[] => {
    const { startYear, endYear } = summary.meta
    return migrationForecastColumnDefs.concat(Array.from({ length: endYear - startYear + 1 }).map((_, i) => {
        const year = startYear + i + ''
        return {
            field: year,
            headerName: year,
            type: 'number',
            editable: false,
            groupable: false,
        }
    }))
}

const generateTableRows = (production: NewBuildingsProductionPerAreaType): GridRowsProp[][] => {

    return Object.values(production).map((area, id) => {

        const { areaName, area: areaCode, ...rest } = area

        return Object.entries(rest).map(([buildingType, values], idx) => {
            return {
                id: areaCode + '-' + idx,
                areaCode,
                areaName,
                buildingType,
                ...Object.entries(values)
                    .map(([year, count]) => ({ [year]: count }))
                    .reduce((prev, curr) => ({ ...prev, ...curr }), {})
            }
        })
    })
        .reduce((prev, curr) => prev.concat(curr), [] as any) as any
}

const BuildingProductionView = () => {

    const forecastId = useAppSelector(selectCurrentForecastId)

    const { data: summary } = useGetNewBuildingsProductionProjectionQuery({ forecastId: forecastId!.id })
    const { data: tableData } = useGetProductionForecastQuery({ forecastId: forecastId!.id })

    const { rows, columnDefs, aggregatedFields } = useMemo(() => {
        if (summary && tableData) {

            const columnDefs = generateColumnDefs(summary)
            const rows = generateTableRows(tableData)

            const aggregatedFields = (({ meta }) => {
                const { startYear, endYear } = meta
                return Array.from({ length: endYear - startYear + 1 }).map((_, i) => {
                    const year = startYear + i + ''
                    return {
                        [year]: 'sum',
                    }
                }).reduce((prev, curr) => ({ ...prev, ...curr }), {}) as any

            })(summary!)

            return { rows, columnDefs, aggregatedFields }
        }
        return {
            rows: [],
            columnDefs: [],
            aggregatedFields: {}
        }
    }, [summary, tableData])

    const apiRef = useGridApiRef();
    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            rowGrouping: {
                model: ['areaName'],
            },
            columns: {
                columnVisibilityModel: {
                    areaCode: false,
                },
            },
        },
    });

    return (
        <Stack>
            <BuildingProductionParameterForm />
            {
                (rows && aggregatedFields) ? <DataGridComponent
                    columns={columnDefs}
                    rows={rows}
                    apiRef={apiRef}
                    initialState={initialState}
                    disableRowSelectionOnClick
                    aggregationModel={aggregatedFields}
                /> : null
            }
        </Stack>
    )
}

export default BuildingProductionView