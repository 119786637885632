import { Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { openDialog } from "../../common/uiSlice"
import { AlertDialogProps } from "../../components/AlertDialog"
import { AuthoredButton } from "../../components/Buttons"
import { ForecastForm } from "../../components/Cards"
import { ForecastType } from "../../types"
import { chooseForecast } from "../apis/appSlice"
import { selectCurrentForecast, selectForecasts, useAddForecastMutation, useDeleteForecastMutation, useEditForecastMutation } from "../apis/forecastApi"

type ForecastViewProps = {
    setDialogProps: (props: AlertDialogProps) => void
}
export const ForecastView = ({ setDialogProps }: ForecastViewProps) => {

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const currentForecast = useAppSelector(selectCurrentForecast)

    const selectForecast = (id: number) => {
        dispatch(chooseForecast({ id }))
    }

    const forecasts = useAppSelector(selectForecasts)

    useEffect(() => {

        if (!forecasts || !forecasts.length) return

        // const onlyForecastHasNoScenarios = forecasts.length === 1 && !forecasts[0].scenarios.length
        // const noScenariosSelected = !(chosenScenario && chosenScenario?.selected)

        // if (onlyForecastHasNoScenarios && noScenariosSelected) {
            if (forecasts.length === 1) selectForecast(forecasts[0]!.id!)
        // }
    }, [forecasts])

    const [addForecastMode, setAddForecastMode] = useState<boolean>(false)

    const [addForecast] = useAddForecastMutation()
    const [editForecast] = useEditForecastMutation()
    const [deleteForecast] = useDeleteForecastMutation()
    // const [lockForecast] = useLockForecastMutation()

    // const onLock = (forecast: ForecastType) => {
    //     const lockFunction = () => lockForecast({ forecastId: forecast.id! })
    //     setDialogProps({
    //         title: t('forecast.lockForecast'),
    //         message: t('forecast.confirmLockForecast'),
    //         onOk: lockFunction
    //     })
    //     dispatch(openDialog())
    // }

    const onDelete = (forecast: ForecastType) => {
        const delFunction = () => deleteForecast({ forecastId: forecast.id! })
        setDialogProps({
            title: t('forecast.deleteForecast'),
            message: t('forecast.confirmDeleteForecast'),
            onOk: delFunction
        })
        dispatch(openDialog())
    }

    return (
        <>
            <Stack>
                <Stack direction={'row'}>
                    <Typography variant="h4">{t('forecast.forecasts')}</Typography>
                    <AuthoredButton
                        variant="contained"
                        onClick={() => setAddForecastMode(true)}
                        size='small'
                        sx={{ marginLeft: '1rem' }}
                    >
                        <span className="material-icons-outlined">library_add</span>
                    </AuthoredButton>
                </Stack>
                <Stack direction={'row'}>
                    {
                        addForecastMode && (
                            <Stack>
                                <Typography variant="h5">{t('forecast.addForecast')}</Typography>
                                <ForecastForm
                                    onSubmit={(forecast: ForecastType) => {
                                        addForecast({ forecast })
                                        setAddForecastMode(false)
                                    }}
                                    forecast={undefined}
                                    onClose={() => setAddForecastMode(false)}
                                />
                            </Stack>
                        )
                    }
                    {
                        forecasts &&
                        [...forecasts].sort((b1, b2) => b1.id! - b2.id!).map((forecast, idx) => (
                            <ForecastForm
                                onSubmit={(forecast: ForecastType) => {
                                    editForecast({ forecast })
                                }}
                                forecast={forecast}
                                key={idx}
                                onSelect={() => selectForecast(forecast.id!)}
                                selected={currentForecast && forecast.id === currentForecast.id && currentForecast.selected}
                                onDelete={onDelete}
                                // lock={() => onLock(forecast)}
                            />
                        ))
                    }
                </Stack>
            </Stack>
        </>
    )
}