import { BuildingTypeType } from '@backend/types';
import { Box, Button, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentForecast, useGetBuildingTypesQuery, useGetNewConstrunctionRealizationPercentageQuery, useUpdateBuildingTypesMutation, useUpdateNewConstrunctionRealizationPercentageMutation } from '../apis/forecastApi';

// FIXME how to re-export enums from backend types?
export enum BuildingProductionType {
    'oktalo' = "1",
    'rivitalo' = "2",
    'kerrostalo' = "3",
}

type NumberFieldProps = TextFieldProps & {
    onChange: (value: any) => void
}
const NumberField = ({ onChange, ...props }: NumberFieldProps) => {

    const isLegalNumber = (value: string) => {
        return !isNaN(Number(value)) || (value.startsWith('-') && !isNaN(Number(value.slice(1))))
    }

    const checkAndSetValue = ({ target }: React.ChangeEvent<HTMLInputElement>) => {

        const { value } = target
        const valueStr = String(value)
        const valueWithoutCommas = valueStr.replace(',', '.')

        if (isLegalNumber(valueWithoutCommas)) {
            onChange(valueWithoutCommas)
        }
    }

    return (
        <TextField onChange={checkAndSetValue} {...props} />
    )
}

type ConstructionInputProps = {
    value?: any,
    onChange: (value: any) => void,
    onBlur?: () => void,
    showPercentage?: boolean,
    disabled?: boolean,
    icon?: string,
}
const ConstructionInput = ({ onChange, value, onBlur, showPercentage, disabled, icon }: ConstructionInputProps) => {

    const { t } = useTranslation()

    return (
        <Box display={'inline-flex'} justifyContent={'flex-start'}>
            <span className="material-icons-outlined" style={{ margin: 'auto', marginRight: '0.5rem' }}>{icon}</span>
            <NumberField sx={{ padding: 0, width: '5rem' }} size="small" value={value} onChange={(e) => onChange(e)} onBlur={onBlur} disabled={disabled} />
        </Box>
    )
}

const HousingDensityParameterForm = () => {

    const { t } = useTranslation()

    const forecast = useAppSelector(selectCurrentForecast)
    const { data } = useGetBuildingTypesQuery({ forecastId: forecast!.id! })
    const [ update ] = useUpdateBuildingTypesMutation()

    const [ buildingTypes, setBuildingTypes ] = useState<BuildingTypeType | undefined>()

    const setValue = (buildingType: BuildingProductionType, value: number) => {
        if (buildingTypes) {
            setBuildingTypes({ ...buildingTypes, [buildingType]: { ...buildingTypes[buildingType], density: Number(value) } })
        }
    }

    useEffect(() => {
        if (data) {
            setBuildingTypes(data)
        }
    }, [data])

    return buildingTypes ? (
        <Stack direction={'row'} justifyItems={'flex-start'} flexBasis={'min-content'} marginTop={'auto'} marginBottom={'auto'}>
            <Box>
                <Typography>{t('buildingProductionView.housingDensity')}</Typography>
                <Typography variant='caption'>{t('buildingProductionView.densityUnit')}</Typography>
            </Box>
            <ConstructionInput
                icon={'house'}
                value={buildingTypes[BuildingProductionType.oktalo]['density']}
                onChange={(value) => setValue(BuildingProductionType.oktalo, value)}
            />
            <ConstructionInput
                icon={'holiday_village'}
                value={buildingTypes[BuildingProductionType.rivitalo]['density']}
                onChange={(value) => setValue(BuildingProductionType.rivitalo, value)}
            />
            <ConstructionInput
                icon={'apartment'}
                value={buildingTypes[BuildingProductionType.kerrostalo]['density']}
                onChange={(value) => setValue(BuildingProductionType.kerrostalo, value)}
            />
            <Button
                sx={{ marginLeft: '0.5rem' }}
                variant={'contained'}
                onClick={() => update({ forecastId: forecast!.id!, buildingTypes })}
            >
                {t('buildingProductionView.submit')}
            </Button>
        </Stack>
    ) : null
}

const NewProductionRealizationPercentageForm = () => {

    const { t } = useTranslation()

    const forecast = useAppSelector(selectCurrentForecast)
    const { data } = useGetNewConstrunctionRealizationPercentageQuery({ forecastId: forecast!.id! })
    const [ update ] = useUpdateNewConstrunctionRealizationPercentageMutation()

    const [ value, setValue ] = useState<number | undefined>()

    useEffect(() => {
        if (data) {
            setValue(data)
        }
    }, [data])

    return value !== undefined ? (
        <Stack direction={'row'} flexBasis={'min-content'} marginTop={'auto'} marginBottom={'auto'}>
            <Typography>{t('buildingProductionView.realizationPercentage')}</Typography>
            <NumberField sx={{ padding: 0, width: '5rem' }} size="small" value={value} onChange={(e) => { setValue(e)} } onBlur={() => { if (!value) setValue(0)} } />
            <Typography sx={{ margin: 'auto' }}>%</Typography>
            <Button
                sx={{ marginLeft: '0.5rem' }}
                variant={'contained'}
                onClick={() => update({ forecastId: forecast!.id!, percentage: value })}
            >
                    {t('buildingProductionView.submit')}
            </Button>
        </Stack>
    ) : null
}

type Props = {
    // Define your component props here
}
const BuildingProductionParameterForm: React.FC<Props> = ({ }) => {

    const { t } = useTranslation()

    return (
        <Grid2 container>
            <Grid2 xs={6}>
                <HousingDensityParameterForm />
            </Grid2>
            <Grid2 xs={6}>
                <NewProductionRealizationPercentageForm />
            </Grid2>
        </Grid2>
    );
};

export default BuildingProductionParameterForm;