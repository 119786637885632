import { Stack } from "@mui/material"
import i18n from 'i18next'
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { PROJECTION_PARAMETERS } from "../../common/constants"
import { chooseParameter, openDrawer, selectChosenParameter } from "../../common/uiSlice"
import { SparkLineLink } from "../../components/charts/LineCharts"
import { ForecastParameterType, ParameterQueryType, ProjectionParameterNameType } from "../../types"
import { selectCurrentForecast } from "../apis/forecastApi"
import { useGetEmigrationForecastQuery, useGetFertilityForecastQuery, useGetImmigrationForecastQuery, useGetInterMunicipalMigrationInForecastQuery, useGetInterMunicipalMigrationOutForecastQuery, useGetMortalityForecastQuery } from "../apis/projectionParametersSlice"

const queryHooks = [
    useGetImmigrationForecastQuery,
    useGetEmigrationForecastQuery,
    useGetInterMunicipalMigrationInForecastQuery,
    useGetInterMunicipalMigrationOutForecastQuery,
]

type MemoizedSparkLineLinkProps = {
    data: ReturnType<typeof queryHooks[0]>['data'],
    inactive: boolean,
}

const generateTitle = (type: ProjectionParameterNameType, method: ForecastParameterType) => {

    if (!type || !method) return ''

    const parameter = PROJECTION_PARAMETERS.find(p => p.id === type)!
    const parameterLabel = i18n.t(parameter.labelKey)
    const forecastMethod = i18n.t(`forecastMethod.${method}`)
    const methodString = !forecastMethod.endsWith('external') ? ` (${forecastMethod})` : ''

    return `${parameterLabel}${methodString}`
}

type MigrationSparkLineLinkProps = {
    hook: typeof queryHooks[0],
    query: ParameterQueryType,
    inactive: boolean,
}

const MemoizedSparkLineLink = ({ data, ...props }:MemoizedSparkLineLinkProps) => {

    const dispatch = useAppDispatch()
    const title = generateTitle(data?.type!, data?.meta?.forecastMethod!)

    const action = () => {
        const { forecastMethod: method, historyYears } = data?.meta!
        dispatch(chooseParameter({ parameterName: data?.type!, forecastParameter: { method, historyYears } }))
        dispatch(openDrawer())
    }

    const chosenParameter = useAppSelector((state: RootState) => state.uiSlice.chosenParameter)

    return (
        (
        data &&
        <SparkLineLink
            action={action}
            title={title}
            data={data?.data ?? []}
            chosen={chosenParameter?.parameterName === data?.type}
            {...props}
        />
         ) || null
    )
}

const MigrationSparkLineLink = ({ hook, query, ...props }:MigrationSparkLineLinkProps) => {

    const { data } = hook(query)

    return (
        <MemoizedSparkLineLink data={data} { ...props } />
    )
}

export const SparkLines = () => {

    const chosenParameter = useAppSelector(selectChosenParameter)

    const forecast = useAppSelector(selectCurrentForecast)!
    const parameterMethods = forecast?.municipalMethods
    const { fromYear, toYear } = forecast

    const { data: fertilityData } = useGetFertilityForecastQuery(
            { fromYear, toYear, forecastMethod: parameterMethods!['fertilityForecast'] },
            {
                selectFromResult: (result) => {
                    if (!result.isSuccess) return { ...result, data: undefined }
                    const { data } = result
                    return {
                        ...result,
                        data: {
                            type: data?.type,
                            meta: data?.meta,
                            data: data?.aggregated.totalFertilityRates,
                        }
                    }
                }
            }
        )

    const { data: mortalityData } = useGetMortalityForecastQuery(
            { fromYear, toYear, forecastMethod: parameterMethods!['mortalityForecast'] },
            {
                selectFromResult: (result) => {
                    if (!result.isSuccess) return { ...result, data: undefined }
                    const { data } = result
                    return {
                        ...result,
                        data: {
                            type: data?.type,
                            meta: data?.meta,
                            data: Object.entries(data!.aggregated?.lifeExpectancies).map(
                                ([year, value]) => ({
                                    year: parseInt(year),
                                    value: (value.f + value.m) / 2,
                                })
                            )
                        }
                    }
                }
            }
        )

    return (
        <Stack alignContent={"space-evenly"}>
            {
                queryHooks.map((hook, idx) => {
                    return <MigrationSparkLineLink
                        hook={hook}
                        query={{ fromYear, toYear, forecastMethod: parameterMethods![PROJECTION_PARAMETERS[idx].id] }}
                        inactive={!!chosenParameter?.parameterName}
                        key={idx}
                    />
                })
            }
            <MemoizedSparkLineLink
                data={fertilityData}
                inactive={!!chosenParameter?.parameterName}
            />
            <MemoizedSparkLineLink
                data={mortalityData}
                inactive={!!chosenParameter?.parameterName}
            />
        </Stack>
    )
}