import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useAppSelector } from "../app/hooks"

type AttributeTextFieldProps = {
    label: string,
    value: string,
    edit?: boolean,
    onUpdate: (e: any) => void
}
const AttributeTextField = ({ label, value, onUpdate }: AttributeTextFieldProps) => {

    const [textValue, setTextValue] = useState<string>('')

    useEffect(() => {
        setTextValue(value)
    }, [value])

    return (
        <FormControl fullWidth>
            <TextField
                value={textValue}
                onChange={({ target }) => setTextValue(target.value)}
                onBlur={onUpdate}
                label={label}
                required
                size='small'
            />
        </FormControl>
    )
}

type YearSelectPropsType = {
    label: string,
    value?: number,
    onUpdate: (e: any) => void,
    disabled?: boolean,
}
const YearSelect = ({ label, value, onUpdate, disabled }: YearSelectPropsType) => {

    const firstForecastYear = useAppSelector(state => state.appSlice.firstForecastYear)

    const id = `year-label-label`
    const from = firstForecastYear

    return (
        <FormControl fullWidth>
            <InputLabel id={id}>{label}</InputLabel>
            <Select labelId={id} label={label} size='small' value={value} onChange={onUpdate} disabled={disabled}>
                {
                    Array.from(Array(40).keys()).map(i => {
                        const year = from + i
                        return <MenuItem key={year} value={year}>{year}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    )
}

type TextComponentProps = {
    variant?: string,
    value: string
}
const BasicTextComponent = ({ value, variant }:TextComponentProps) => (
    <Typography variant={variant as any} component="p">
        {value}
    </Typography>
)

type ElasticInputFieldProps = {
    editMode: boolean,
    inputComponent: JSX.Element,
    textComponent: JSX.Element
}
const ElasticInputField = ({editMode, inputComponent, textComponent}:ElasticInputFieldProps) => (
    editMode ? inputComponent : textComponent
)

type ElasticTextFieldProps = {
    label: string,
    variant?: string,
    value: string,
    editMode?: boolean,
    onUpdate: (e: any) => void
}
export const BasicElasticTextField = ({ label, variant = 'body2', value, editMode = false, onUpdate }: ElasticTextFieldProps) => {
    return ElasticInputField({
            editMode,
            inputComponent: <AttributeTextField label={label} value={value} onUpdate={onUpdate} />,
            textComponent: <BasicTextComponent variant={variant as any} value={value} />
        })
}

type ElasticYearSelectPropsType = YearSelectPropsType & { edit: boolean }
export const ElasticYearSelect = ({ label, value, edit = false, onUpdate, disabled }: ElasticYearSelectPropsType) => {
    return (
        edit ?
            <YearSelect label={label} value={value} onUpdate={onUpdate} disabled={disabled} />
            :
            <Typography variant="body2" component="p">
                {label}: {value}
            </Typography>
    )
}

type TooltipTextComponentProps = {
    value: string,
    tooltipValue: string,
}
export const TooltipTextComponent = ({ value, tooltipValue }: TooltipTextComponentProps) => (
    <Box sx={{ display: 'inline-flex' }}>
        <Button sx={{ margin: 0, padding: 0 }}>
            <span className="material-icons-outlined" style={{ fontSize: '1rem', verticalAlign: 'middle', float: 'left' }}>info</span>&nbsp;
        </Button>
        <Typography variant="body2" component="p" title={tooltipValue}>
            <b>{value}</b>
        </Typography>
    </Box>
)

type OriginalValueElasticTextFieldProps = ElasticTextFieldProps & { originalValue?: any }
export const OriginalValueElasticTextField = ({ label, value, originalValue, editMode = false, onUpdate }: OriginalValueElasticTextFieldProps) => {
    return ElasticInputField({
        editMode,
        inputComponent: <AttributeTextField label={label} value={value} onUpdate={onUpdate} />,
        textComponent: !originalValue ?
            <BasicTextComponent value={value} /> : <TooltipTextComponent value={value} tooltipValue={originalValue} />
    })
}