import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { YearAndValueType } from '../../types';

export type SparkLineProps =  {
  data: YearAndValueType[],
  title?: string
}

export type SparkLineLinkProps = SparkLineProps & {
  action: () => void,
  chosen: boolean,
  inactive: boolean,
}

export const SparkLine = ({ data, title }: SparkLineProps) => {
  return (
    <div style={{ margin: 0, padding: 0 }}>
      <h3 style={{ fontSize: '0.7rem', fontWeight: '700' }}>{title}</h3>
      <ResponsiveContainer width={'100%'} aspect={3}>
        <LineChart data={data} margin={{ left: -60 }}>
          <XAxis dataKey="year" tickLine={false} tick={false} />
          <YAxis tickLine={false} tick={false} />
          <Line
            dot={false}
            legendType='none'
            type="monotone"
            dataKey={"value"}
            stroke={'#70C4F2'}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export const SparkLineLink = ({ data, title, action, chosen, inactive }: SparkLineLinkProps) => {

  const [hover, setHover] = useState<boolean>(false)

  return (
    <div
      onClick={
        inactive ? () => { } : () => action()
      }
      onMouseEnter={inactive ? () => { } : () => setHover(true)}
      onMouseLeave={!chosen && inactive ? () => { } : () => setHover(false)}
      style={{
        cursor: hover && !inactive ? 'pointer' : 'default',
        background: hover || chosen ? '#C4E8FA' : '#FFF'
      }}>
      <SparkLine data={data} title={title} />
    </div>
  )}

const theCTOFunction = (minHistoryYear:number, maxHistoryYear:number, maxForecastYear:number) => (maxHistoryYear - minHistoryYear) / (maxForecastYear - minHistoryYear)

type LineChartViewType = {
  data: YearAndValueType[],
  history: YearAndValueType[],
  dataKeys: string[],
  label: string,
  roundTo?: number
}
export const ParameterLineChart = ({ label, history, data, dataKeys, roundTo }: LineChartViewType) => {

  const { t } = useTranslation()

  const [lineColorChangePercentage, setLineColorChangePercentage] = useState<number>(0)

  useEffect(() => {

    if (!data || !history) return

    const minHistoryYear = Math.min(...history.map(({ year}) =>  year))
    const maxHistoryYear = Math.max(...history.map(({ year}) =>  year))
    const maxForecastYear = Math.max(...data.map(({ year}) =>  year))
    const percentage = theCTOFunction(minHistoryYear, maxHistoryYear, maxForecastYear) * 100

    setLineColorChangePercentage(percentage)

  }, [data, history, lineColorChangePercentage ])

  const formatValue = (value: number) => {
    return roundTo ? value.toFixed(roundTo) : Math.round(value)
  }

  const tooltipFormatter = (value: number, name: string | number, props: any) => [ `${formatValue(value)} ${t('charts.persons')}`, `${props.payload.year} ` ]

  return (
    (
      data.length &&
      <ResponsiveContainer height={'100%'} aspect={1}>
        <LineChart
          width={500}
          height={300}
          data={data.concat(history).sort((a, b) => a.year - b.year)}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="100%" y2="0">
              <stop offset="0%" stopColor="#70C4F2" />
              <stop offset={lineColorChangePercentage + '%'} stopColor="#70C4F2" />
              <stop offset={lineColorChangePercentage + '%'} stopColor="#C4E8FA" />
              <stop offset="100%" stopColor="#C4E8FA" />
            </linearGradient>
          </defs>

          <Tooltip
            formatter={tooltipFormatter}
            labelFormatter={() => ''}
          />

          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={'year'}>
            <Label value={label} offset={-5} position="insideBottom" />
          </XAxis>
          <YAxis />
          <Tooltip />
          {
            dataKeys.map((key, idx) => {
              return <Line key={idx} dataKey={key} stroke='url(#colorUv)' strokeWidth={3} dot={false} />
            })
          }
        </LineChart>
      </ResponsiveContainer>
    )) || null;
}

type AreaPopulationLineChartType = {
  data: YearAndValueType[],
  label: string,
}
export const AreaPopulationLineChart = ({ label, data }: AreaPopulationLineChartType) => {
  return (
    (
      data.length &&
      <ResponsiveContainer height={'100%'} aspect={2}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={'year'}>
            <Label value={label} offset={-5} position="insideBottom" />
          </XAxis>
          <YAxis />
          <Tooltip />
          <Line dataKey={'value'} strokeWidth={3} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    )) || null;
}
