import { ProjectionMethodUIType, ProjectionParameterUIType } from "../types"

export const COLORS = ['#70C4F2', '#C4E8FA', '#A3EBA3', '#D6F2D6']

export const PROJECTION_METHODS: ProjectionMethodUIType[] = [
    {
        id: 'mean',
        labelKey: 'forecastMethod.mean',
    },
    {
        id: 'latest',
        labelKey: 'forecastMethod.latest',
    },
    {
        id: 'linear_regression',
        labelKey: 'forecastMethod.linear_regression',
    },
    {
        id: 'moving_average',
        labelKey: 'forecastMethod.moving_average',
    },
    {
        id: 'weighted_moving_average',
        labelKey: 'forecastMethod.weighted_moving_average',
    },
    {
        id: 'ARIMA',
        labelKey: 'forecastMethod.ARIMA',
    },
]

export const PROJECTION_PARAMETERS: ProjectionParameterUIType[] = [
    {
        id: 'immigration',
        labelKey: 'projectionParameter.immigration',
    },
    {
        id: 'emigration',
        labelKey: 'projectionParameter.emigration',
    },
    {
        id: 'interMunicipalMigrationIn',
        labelKey: 'projectionParameter.interMunicipalMigrationIn',
    },
    {
        id: 'interMunicipalMigrationOut',
        labelKey: 'projectionParameter.interMunicipalMigrationOut',
    },
    {
        id: 'fertilityForecast',
        labelKey: 'projectionParameter.fertilityForecast',
    },
    {
        id: 'mortalityForecast',
        labelKey: 'projectionParameter.mortalityForecast',
    },
]

export const AREA_PROJECTION_PARAMETERS: any[] = [
    ...PROJECTION_PARAMETERS,
    {
        id: 'intraMunicipalMigrationIn',
        labelKey: 'projectionParameter.intraMunicipalMigrationIn',
    },
    {
        id: 'intraMunicipalMigrationOut',
        labelKey: 'projectionParameter.intraMunicipalMigrationOut',
    },
]
