import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { GridColDef } from "@mui/x-data-grid"
import { GridRowSelectionModel } from "@mui/x-data-grid-premium"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { openDrawer, selectDrawerOpen } from "../../common/uiSlice"
import { DIYDrawer } from "../../components/Drawer"
import { DataGridComponent } from "../../components/Tables"
import { AreaPopulationType, AreaSummaryType } from "../../types"
import { selectCurrentForecast, useGetAreaDataModificationsQuery, useGetForecastAreaSummaryDataQuery } from "../apis/forecastApi"
import AreaCodeView, { ComparedToType } from "./AreaCodeView"
import AreaTotalsView from "./AreaTotalsView"
import EditAreaParameters, { AreaCodeNameType } from "./EditAreaParameters"

export const migrationForecastColumnDefs: GridColDef[] = [
    { field: 'areaCode' },
    { field: 'name', headerName: '', width: 170 },
    {
        field: 'netMigration',
        width: 150,
        editable: false,
    },
    {
        field: 'netIntraMunicipalMigration',
        width: 150,
        editable: false,
    },
    {
        field: 'netInterMunicipalMigration',
        width: 150,
        editable: false,
    },
    {
        field: 'births',
        width: 150,
        editable: false,
    },
    {
        field: 'deaths',
        width: 150,
        editable: false,
    },
]

type AreaTableProps = {
    editMode: boolean
    setEditMode: (editMode: boolean) => void,
    setDialogProps: (dialogProps: { title: string, message: string }) => void
}

const AreaTable = ({ editMode, setEditMode, setDialogProps }: AreaTableProps) => {

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const forecast = useAppSelector(selectCurrentForecast)
    const drawerOpen = useAppSelector(selectDrawerOpen)

    const [forecastType, setForecastType] = useState<'forecast' | 'scenario'>('forecast')

    const { hook, parameters } = useMemo(() => {
        setForecastType('forecast')
        return { hook: useGetForecastAreaSummaryDataQuery, parameters: { forecastId: forecast!.id } }
    }, [forecast]) as any

    const { data }: { data: AreaSummaryType } = hook(parameters)
    const { data: modifications } = useGetAreaDataModificationsQuery({ forecastId: forecast!.id! })

    const [drawerView, setDrawerView] = useState<string>('-1')
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
    const [selectedAreas, setSelectedAreas] = useState<AreaCodeNameType[]>([])

    useEffect(() => {
        setSelectedAreas((rowSelectionModel as number[]).map(r => { const { areaCode, name: areaName } = rows[r + 1] as any; return { areaCode, areaName } }))
    }, [rowSelectionModel])

    const onDeleteArea = (areaCode: string) => {
        setRowSelectionModel((rowSelectionModel as number[]).filter(r => (rows[r + 1] as any).areaCode !== areaCode))
    }

    const { rows, population, areas, meta, populationChangeComponents } = useMemo(() => {

        if (data && modifications) {

            const { populationChangeComponents } = data

            const {
                emigrationAvg,
                immigrationAvg,
                intraMunicipalMigrationInAvg,
                intraMunicipalMigrationOutAvg,
                interMunicipalMigrationInAvg,
                interMunicipalMigrationOutAvg,
                birthsAvg,
                deathsAvg,
            } = populationChangeComponents

            const aggregatedRow = {
                id: -1,
                name: 'Yhteensä',
                netMigration: Math.round(Object.values(immigrationAvg).reduce((a, b) => a + b, 0) - Object.values(emigrationAvg).reduce((a, b) => a + b, 0)),
                netIntraMunicipalMigration: Math.round(Object.values(intraMunicipalMigrationInAvg).reduce((a, b) => a + b, 0) - Object.values(intraMunicipalMigrationOutAvg).reduce((a, b) => a + b, 0)),
                netInterMunicipalMigration: Math.round(Object.values(interMunicipalMigrationInAvg).reduce((a, b) => a + b, 0) - Object.values(interMunicipalMigrationOutAvg).reduce((a, b) => a + b, 0)),
                births: Math.round(Object.values(birthsAvg).reduce((a, b) => a + b, 0)),
                deaths: Math.round(Object.values(deathsAvg).reduce((a, b) => a + b, 0)),
            }

            return {
                rows: [aggregatedRow].concat(Object.entries(data.areas).map(([id, name], idx) => {

                    const netMigration = Math.round(immigrationAvg[id] - emigrationAvg[id])
                    const netIntraMunicipalMigration = Math.round(intraMunicipalMigrationInAvg[id] - intraMunicipalMigrationOutAvg[id])
                    const netInterMunicipalMigration = Math.round(interMunicipalMigrationInAvg[id] - interMunicipalMigrationOutAvg[id])
                    const births = Math.round(birthsAvg[id])
                    const deaths = Math.round(deathsAvg[id])

                    return {
                        id: idx,
                        areaCode: id,
                        name,
                        netMigration,
                        netIntraMunicipalMigration,
                        netInterMunicipalMigration,
                        births,
                        deaths,
                    }
                })),
                population: data.population,
                areas: data.areas,
                meta: data.meta,
                populationChangeComponents,
                modifications,
            }
        }
        return {
            rows: [],
            population: {} as AreaPopulationType,
            populationChangeComponents: {} as any,
            modifications: {} as any,
            areas: {} as { [id: string]: string },
            meta: {} as { [id: string]: string },
            comparedTo: {} as { [id: string]: ComparedToType },
        }
    }, [data, modifications])

    return (
        <Grid2 container>
            <Grid2 xs={8} padding={0}>
                <DataGridComponent
                    sx={{ position: 'relative', width: 'inherit', border: 'none', }}
                    columns={
                        migrationForecastColumnDefs.map((column) => ({
                            ...column,
                            headerName: t(`areaView.tableFields.${column.field}`),
                        }))
                    }
                    rows={rows}
                    getRowClassName={
                        (params) => {
                            if (params.row.id === -1) return `super-app-theme--FirstRow`
                            else if (Object.keys(modifications!).includes(params.row.areaCode)) return `super-app-theme--BoldRow`
                            else return ''
                        }
                    }
                    pinnedRows={{
                        top: [rows[0] || { id: -1 }]
                    }}
                    onRowClick={({ row }) => {
                        const { areaCode } = row
                        // if (!scenario || !scenario.selected) setEditMode(false)
                        setDrawerView(areaCode || '-1')
                        !drawerOpen && dispatch(openDrawer())
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                areaCode: false,
                            },
                        },
                    }}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={setRowSelectionModel}
                />
            </Grid2>
            <Grid2 xs={4}>
            {
                    drawerView === '-1' ? (
                        <AreaTotalsView />
                    ) : (
                        !editMode ?
                            <AreaCodeView
                                areaName={areas[drawerView!]}
                                areaCode={drawerView!}
                                population={population}
                                populationChange={populationChangeComponents}
                                startYear={meta.startYear as number}
                                endYear={meta.endYear as number}
                                setDialogProps={setDialogProps}
                                modifications={modifications || {}}
                                editable={true}
                            />
                            :
                            <DIYDrawer>
                                <EditAreaParameters areas={selectedAreas} onDeleteArea={onDeleteArea} onClose={() => setEditMode(false)} />
                            </DIYDrawer>
                    )
                }            </Grid2>
        </Grid2>
    )
}

export default AreaTable