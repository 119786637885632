import { useTranslation } from "react-i18next";
import { DataGridComponent } from "./Tables";
import { migrationForecastColumnDefs } from "./tableTypes";

type TableViewPropsType = {
    data: {
        year: number,
        immigration: number,
        emigration: number,
        interMunicipalMigrationIn: number,
        interMunicipalMigrationOut: number,
    }[]
}

export const TableView = ({ data }: TableViewPropsType) => {

    const { t } = useTranslation()

    return (
        data ?
            <DataGridComponent
                columns={
                    migrationForecastColumnDefs.map((column) => ({
                        ...column,
                        headerName: t(`projectionParameter.${column.field}`),
                        disableClickEventBubbling: true,
                        valueGetter: ({ field, value }) => {
                            return field === 'year' ? value : Math.round(value)
                        }
                    }))
                }
                rows={data.map((row, id) => ({ ...row, id }) )}
            />
            : null
    )
}