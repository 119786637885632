import { Box, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { useAppSelector } from '../../app/hooks';
import { getPopulationAgeRanges, getPopulationPercentageRanges } from '../../common/functions';
import { CohortByAreaByYearType } from '../../types';
import { selectCurrentForecast, useGetForecastMunicipalDataQuery } from '../apis/forecastApi';

const AGE_GROUPS = [
  { from: 0, to: 4 },
  { from: 5, to: 9 },
  { from: 10, to: 14 },
  { from: 15, to: 19 },
  { from: 20, to: 24 },
  { from: 25, to: 29 },
  { from: 30, to: 34 },
  { from: 35, to: 39 },
  { from: 40, to: 44 },
  { from: 45, to: 49 },
  { from: 50, to: 54 },
  { from: 55, to: 59 },
  { from: 60, to: 64 },
  { from: 65, to: 69 },
  { from: 70, to: 74 },
  { from: 75, to: 79 },
  { from: 80, to: 84 },
  { from: 85, to: 89 },
  { from: 90, to: 94 },
  { from: 95, to: 100 },
]

export type PopulationGroupPercentages = {
  [group: string]: {
    label: string,
    change: [number, number],
  }
}[]

export type PopulationGroupSizes = {
  [year: number]: {
    [sex: string]: [{
      [ageGroup: string]: {
        label: string,
        size: number,
      }
    }]
  }
}[]

const ChangeBarChart = () => {

  const forecast = useAppSelector(selectCurrentForecast)

  // const { hook, parameters } = useMemo(() => {
  //   if (scenario) {
  //     return { hook: useGetMunicipalDataForScenarioQuery, parameters: { scenarioId: scenario.id } }
  //   } else {
  //     return { hook: useGetForecastMunicipalDataQuery, parameters: { forecastId: forecast!.id } }
  //   }
  // }, [forecast, scenario]) as any

  // const { data } = hook(parameters)

  const { data } = useGetForecastMunicipalDataQuery({ forecastId: forecast!.id! })

  const ageGroups = useMemo(() => {
    if (!!!data) return []
    return getPopulationPercentageRanges(data.data as CohortByAreaByYearType, AGE_GROUPS)
  }, [data])

  const tooltipFormatter = (value: (string | number)[], name: string | number) => {
    return [`${value[0]} - ${value[1]} %`, t('charts.change')]
  }

  const legendFormatter = (value: any) => {
    return t('charts.changeInTimePeriodByAge')
  }

  return (
    <ResponsiveContainer width="100%" height="100%" aspect={0.65}>
      <BarChart
        layout="vertical"
        stackOffset="sign"
        data={Object.values(ageGroups)}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis orientation='top' type='number' domain={[-1, 1]} tickCount={9} />
        <YAxis dataKey='label' type='category' />
        <Tooltip formatter={tooltipFormatter} />
        <Legend formatter={legendFormatter} />
        <Bar dataKey="change" fill='#C4E8FA' stroke='#70C4F2' barSize={15} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export const AgeGroupDiagram = () => {

  const { t } = useTranslation()

  const forecast = useAppSelector(selectCurrentForecast)
  // const scenario = useAppSelector(selectCurrentScenario)

  // const { hook, parameters } = useMemo(() => {
  //   if (scenario) {
  //     return { hook: useGetMunicipalDataForScenarioQuery, parameters: { scenarioId: scenario.id } }
  //   } else {
  //     return { hook: useGetForecastMunicipalDataQuery, parameters: { forecastId: forecast!.id } }
  //   }
  // }, [forecast, scenario]) as any

  // const { data } = hook(parameters)

  const { data } = useGetForecastMunicipalDataQuery({ forecastId: forecast!.id! })

  const { ageGroups, maxSize, minYear, maxYear } = useMemo(() => {
    if (!!!data) return { ageGroups: [], maxSize: 0, minYear: 0, maxYear: 0 }
    return getPopulationAgeRanges(data.data as CohortByAreaByYearType, AGE_GROUPS)
  }, [data])

  const tooltipFormatter = (value: (string | number)[], name: string | number) => {
    return [String(Math.abs(Math.round(Number(value)))), name]
  }

  return ageGroups.length > 0 ? (
    <Box>

      <Typography variant='h3' style={{ fontSize: '1.2rem', fontWeight: 700, paddingTop: '0.6rem', paddingBottom: '0.6rem' }}>
        {t('charts.ageDistribution')}
      </Typography>

      <Grid2 container textAlign={'center'} paddingLeft={'3.5rem'}>
        <Grid2 xs={6}>
          <Typography variant='h3' style={{ fontSize: '1.2rem' }}>{t('charts.women')}</Typography>
        </Grid2>
        <Grid2 xs={6}>
          <Typography variant='h3' style={{ fontSize: '1.2rem' }}>{t('charts.men')}</Typography>
        </Grid2>
      </Grid2>
      <ResponsiveContainer width="100%" height="100%" aspect={0.76} >
        <LineChart
          layout="vertical"
        >
          <XAxis
            dataKey={'size'}
            type={'number'}
            domain={[-maxSize, maxSize]}
            tickFormatter={(value) => { return isNaN(value) ? value : String(Math.abs(Number(value))) }}
            interval={'preserveStartEnd'}
          />

          <YAxis allowDuplicatedCategory={false} orientation='left' dataKey={'label'} type={'category'} interval={0} padding={{ top: 20, bottom: 20 }} />

          <Tooltip formatter={tooltipFormatter} />

          <Line dataKey="size" data={ageGroups[0][minYear]['female']} name={t('charts.women') + ' ' + minYear}
            stroke='#1F4054' strokeWidth={3} />
          <Line dataKey="size" data={ageGroups[0][minYear]['male']} name={t('charts.men') + ' ' + minYear}
            stroke='#1F4054' strokeWidth={3} />

          <Line dataKey="size" data={ageGroups[1][maxYear]['female']} name={t('charts.women') + ' ' + maxYear}
            stroke='#70C4F2' strokeWidth={3} />
          <Line dataKey="size" data={ageGroups[1][maxYear]['male']} name={t('charts.men') + ' ' + maxYear}
            stroke='#70C4F2' strokeWidth={3} />

        </LineChart>
      </ResponsiveContainer>
      <Box textAlign={'center'} paddingLeft={'3.5rem'}>
        <Typography variant='h3' fontSize={'1.2rem'} fontWeight={700} color={'#1F4054'}>{minYear}</Typography>
        <Typography variant='h3' fontSize={'1.2rem'} fontWeight={700} color={'#70C4F2'}>{maxYear}</Typography>
      </Box>
    </Box>
  ) : null
}

export default ChangeBarChart;
