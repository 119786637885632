import { GeoJSONSource, Map } from 'mapbox-gl';
import { GeoJSONFeature } from 'wellknown';

export const addAreaData = (map: Map, data: GeoJSONFeature[]) => {

    map.addSource('geometries', {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: data as any
        }
    });

    addLayers(map)
}

export const setAreaData = (map: Map, data: GeoJSONFeature[]) => {

    (map.getSource('geometries') as GeoJSONSource).setData({
        type: 'FeatureCollection',
        features: data as any
    })
}

export const addLayers = (map: Map) => {

    map.addLayer({
        'id': 'outline',
        'type': 'line',
        'source': 'geometries',
        'layout': {},
        'paint': {
            'line-color': '#000',
            'line-width': 1
        }
    });

    map.addLayer({
        'id': 'area-name',
        'type': 'symbol',
        'source': 'geometries',
        'minzoom': 11.5,
        'layout': {
            'text-field': ['get', 'name'],
            'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
            'text-radial-offset': 0.5,
            'text-justify': 'auto',
        }
    })

}