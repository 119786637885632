import { Box, Button, FormControlLabel, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../app/hooks"
import { AlertDialogProps } from "../../components/AlertDialog"
import { OriginalValueElasticTextField } from "../../components/Forms"
import { AreaPopulationLineChart } from "../../components/charts/LineCharts"
import { AreaPopulationType, ModifiedPopulationChangeType, PopulationChangeType } from "../../types"
import { selectCurrentForecastId } from "../apis/appSlice"
import { useModifyAreaDataMutation } from "../apis/forecastApi"

export type ComparedToType = {
    [year: number]: {
        order: number, of: number
    }
}

type MigrationChangeType = {
    [key: string]: {
        value: number,
        originalValue?: number,
        valueInThisSession?: number,
    }
}

type PopulationChangeTableType = {
    changes: PopulationChangeType,
    areaCode: string,
    // editMode?: boolean,
}
type MigrationChangeTableType = PopulationChangeTableType & {
    editable?: boolean,
    // setEditMode: (editMode: boolean) => void,
    setDialogProps: (dialogProps: AlertDialogProps) => void,
    modifications: ModifiedPopulationChangeType,
}
const MigrationTable = ({ changes, modifications, areaCode, setDialogProps, editable = false }: MigrationChangeTableType) => {

    const { t } = useTranslation()

    const { id: forecastId } = useAppSelector(selectCurrentForecastId)!
    const [ updateArea ] = useModifyAreaDataMutation()

    const [editMode, setEditMode] = useState(false)
    const [ values, setValues ] = useState<MigrationChangeType | undefined>()

    const initValues = (changes: PopulationChangeType) => {

        if (!changes) return

        const v = Object.entries(changes).reduce((prev, [key, value]) => ({
            ...prev,
            [key]: {
                value: Math.round(value[areaCode]),
            }
        }), {} as any)

        setValues(
            Object.entries(v).map(([paramName, value]) => ({
                [paramName]: {
                    ...value as any,
                    originalValue: (modifications || {})[paramName]?.originalValue,
                }
            })).reduce((prev, curr) => ({ ...prev, ...curr }), {} as any)
        )

        setEditMode(false)

    }

    useEffect(() => {
        initValues(changes)
    }, [changes, modifications, areaCode])

    // const totalAreas = Object.keys(changes.immigrationAvg).length

    // const migrationNets = {
    //     migrationNet: Object.keys(changes['immigrationAvg']).map(areaCode => ({ [areaCode]: changes['immigrationAvg'][areaCode] - changes['emigrationAvg'][areaCode] })).sort((a, b) => Object.values(b)[0] - Object.values(a)[0]).reduce((prev, curr) => ({ ...prev, ...curr }), {} as any),
    //     intraMunicipalNet: Object.keys(changes['intraMunicipalMigrationInAvg']).map(areaCode => ({ [areaCode]: changes['intraMunicipalMigrationInAvg'][areaCode] - changes['intraMunicipalMigrationOutAvg'][areaCode] })).sort((a, b) => Object.values(b)[0] - Object.values(a)[0]).reduce((prev, curr) => ({ ...prev, ...curr }), {} as any),
    //     interMunicipalNet: Object.keys(changes['interMunicipalMigrationInAvg']).map(areaCode => ({ [areaCode]: changes['interMunicipalMigrationInAvg'][areaCode] - changes['interMunicipalMigrationOutAvg'][areaCode] })).sort((a, b) => Object.values(b)[0] - Object.values(a)[0]).reduce((prev, curr) => ({ ...prev, ...curr }), {} as any),
    // }

    // const migrationOrder = Object.keys(migrationNets).reduce((prev, migrationType) => ({
    //     ...prev,
    //     [migrationType]: Object.keys(migrationNets[migrationType as keyof (typeof migrationNets)])
    //         .findIndex(key => key === areaCode) + 1,
    // }), {} as any)

    const updateChange = (key: string, value: string) => {
        
        if (values![key].value === Number(value)) return
        
        const newValues = {
            ...values,
            [key]: {
                ...values![key],
                valueInThisSession: Number(value),
            }
        }

        setValues(newValues)
    }

    const getValue = (key: string) => String(values?.[key]?.valueInThisSession! || values?.[key]?.value!)

    const cancel = () => initValues(changes)
    
    const saveChanges = () => {
            updateArea({
                forecastId,
                areaCode,
                modifications: Object.entries(values!).filter(([_, value]) => value.valueInThisSession !== undefined).reduce((prev, [key, value]) => ({
                    ...prev,
                    [key]: {
                        value: value.valueInThisSession,
                        originalValue: value.originalValue || value.value,
                    }
                }), {} as any)
            })
            setEditMode(false)
    }
    const anyValueHasChanged = !!Object.values(values || {}).find(value => value.valueInThisSession !== undefined)
    
    const setOriginalValues = () => {
        setValues(
            Object.entries(values!).map(([key, value]) => ({
                [key]: {
                    ...value,
                    valueInThisSession: value.originalValue,
                }
            })).reduce((prev, curr) => ({ ...prev, ...curr }), {} as any)
        )
        setEditMode(true)
    }

    return values ? (
        <Stack>
            <h4>{t('areaView.migration')}</h4>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                            {
                                editable ?
                                <FormControlLabel
                                    control={
                                        <Switch checked={editMode} value={'check'} onChange={() => setEditMode(!editMode)} />
                                    }
                                    label={t('general.editMode')}
                                /> : null
                                }
                                {
                                    editable && !!modifications ? (
                                    <Box display={'inline-flex'}>
                                        <Button
                                            variant="text"
                                            // onClick={() => setModified(originalValues)}
                                            onClick={() => setOriginalValues()}
                                            size="small"
                                        >
                                            <span className="material-icons-outlined">
                                                undo
                                            </span>
                                        </Button>
                                        <Typography variant="caption">Palauta alkuperäiset</Typography>
                                    </Box>
                                    ) : null
                                    }
                            </TableCell>
                            <TableCell align="right">{t('areaView.intraMunicipal')}</TableCell>
                            <TableCell align="right">{t('areaView.interMunicipal')}</TableCell>
                            <TableCell align="right">{t('areaView.immigrationEmigration')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="right">{t('areaView.migrationIn')}</TableCell>
                            <TableCell align="right">
                                <OriginalValueElasticTextField
                                    label={t('areaView.intraMunicipal')}
                                    value={getValue('intraMunicipalMigrationInAvg')}
                                    originalValue={values!['intraMunicipalMigrationInAvg'].originalValue}
                                    editMode={editMode}
                                    onUpdate={({ target: { value } }) => updateChange('intraMunicipalMigrationInAvg', value)}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <OriginalValueElasticTextField
                                    label={t('areaView.interMunicipal')}
                                    value={getValue('interMunicipalMigrationInAvg')}
                                    originalValue={values!['interMunicipalMigrationInAvg'].originalValue}
                                    editMode={editMode}
                                    onUpdate={({ target: { value } }) => updateChange('interMunicipalMigrationInAvg', value)}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <OriginalValueElasticTextField
                                    label={t('areaView.immigrationEmigration')}
                                    value={getValue('immigrationAvg')}
                                    originalValue={values!['immigrationAvg'].originalValue}
                                    editMode={editMode}
                                    onUpdate={({ target: { value } }) => updateChange('immigrationAvg', value)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">{t('areaView.migrationOut')}</TableCell>
                            <TableCell align="right">
                                <OriginalValueElasticTextField
                                    label={t('areaView.intraMunicipal')}
                                    value={getValue('intraMunicipalMigrationOutAvg')}
                                    originalValue={values!['intraMunicipalMigrationOutAvg'].originalValue}
                                    editMode={editMode}
                                    onUpdate={({ target: { value } }) => updateChange('intraMunicipalMigrationOutAvg', value)}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <OriginalValueElasticTextField
                                    label={t('areaView.interMunicipal')}
                                    value={getValue('interMunicipalMigrationOutAvg')}
                                    originalValue={values!['interMunicipalMigrationOutAvg'].originalValue}
                                    editMode={editMode}
                                    onUpdate={({ target: { value } }) => updateChange('interMunicipalMigrationOutAvg', value)}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <OriginalValueElasticTextField
                                    label={t('areaView.immigrationEmigration')}
                                    value={getValue('emigrationAvg')}
                                    originalValue={values!['emigrationAvg'].originalValue}
                                    editMode={editMode}
                                    onUpdate={({ target: { value } }) => updateChange('emigrationAvg', value)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">{t('areaView.netPerYear')}</TableCell>
                            <TableCell align="right">{Number(getValue('intraMunicipalMigrationInAvg')) - Number(getValue('intraMunicipalMigrationOutAvg'))}</TableCell>
                            <TableCell align="right">{Number(getValue('interMunicipalMigrationInAvg')) - Number(getValue('interMunicipalMigrationOutAvg'))}</TableCell>
                            <TableCell align="right">{Number(getValue('immigrationAvg')) - Number(getValue('emigrationAvg'))}</TableCell>
                        </TableRow>
                        {/*
                            !editMode &&
                            <TableRow>
                                <TableCell align="right">{t('areaView.comparedToOthers')}</TableCell>
                                <TableCell align="right">{migrationOrder.intraMunicipalNet} / {totalAreas}</TableCell>
                                <TableCell align="right">{migrationOrder.interMunicipalNet} / {totalAreas}</TableCell>
                                <TableCell align="right">{migrationOrder.migrationNet} / {totalAreas}</TableCell>
                            </TableRow>
                        */} 
                        {
                            editMode &&
                            <TableRow>
                                <TableCell colSpan={4} align="right">
                                    <Button variant="outlined" onClick={() => cancel()}>
                                        {t('general.cancel')}
                                    </Button>
                                    <Button variant="contained" disabled={!anyValueHasChanged} onClick={() => saveChanges()}>
                                        {t('general.save')}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    ) : null
}

const NaturalChangeTable = ({ changes, areaCode }: PopulationChangeTableType) => {

    const { t } = useTranslation()

    const { birthsAvg, deathsAvg } = Object.keys(changes).reduce((prev, change) => ({
        ...prev,
        [change]: Math.round(changes[change as keyof PopulationChangeType][areaCode])
    }), {} as any)

    // const totalAreas = Object.keys(changes.birthsAvg).length

    // const naturalChange = {
    //     net: Object.keys(changes['birthsAvg']).map(areaCode => ({ [areaCode]: changes['birthsAvg'][areaCode] - changes['deathsAvg'][areaCode] })).sort((a, b) => Object.values(b)[0] - Object.values(a)[0]).reduce((prev, curr) => ({ ...prev, ...curr }), {} as any),
    // }

    // const changeOrder = Object.keys(naturalChange).reduce((prev, migrationType) => ({
    //     ...prev,
    //     [migrationType]: Object.keys(naturalChange[migrationType as keyof (typeof naturalChange)])
    //         .findIndex(key => key === areaCode) + 1,
    // }), {} as any)

    return (
        <Stack>
            <h4>{t('areaView.naturalChange')}</h4>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">{t('areaView.births')}</TableCell>
                            <TableCell align="right">{t('areaView.deaths')}</TableCell>
                            {/* <TableCell align="right">{t('areaView.comparedToOthers')}</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="right">{t('areaView.averageChangePerYear')}</TableCell>
                            <TableCell align="right">{birthsAvg}</TableCell>
                            <TableCell align="right">{deathsAvg}</TableCell>
                            {/* <TableCell align="right">{changeOrder.net} / {totalAreas}</TableCell> */}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    )
}

type AreaCodeViewProps = {
    areaCode: string,
    areaName: string,
    population: AreaPopulationType,
    populationChange: PopulationChangeType,
    startYear: number,
    endYear: number,
    // editMode: boolean,
    editable: boolean,
    // setEditMode: (editMode: boolean) => void,
    setDialogProps: (dialogProps: AlertDialogProps) => void,
    modifications: { [areaCode:string]: ModifiedPopulationChangeType },
}

const AreaCodeView = ({ editable = false, areaCode, areaName, population, populationChange, setDialogProps, modifications }: AreaCodeViewProps) => {

    return (
        <Stack padding={'1rem'}>
            <h3>{areaName}</h3>
            <hr />
            <AreaPopulationLineChart
                data={
                    Object.entries(population[areaCode])
                        .map(([ year, value ]) =>
                        ({ year: Number(year), value }), [])
                    }
                label={'Population'}
            />
            <MigrationTable
                changes={populationChange}
                areaCode={areaCode}
                editable={editable}
                setDialogProps={setDialogProps}
                modifications={modifications[areaCode]}
            />
            <NaturalChangeTable changes={populationChange} areaCode={areaCode} />
        </Stack>
    )
}

export default AreaCodeView