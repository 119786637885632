import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ForecastParameterType, ProjectionParameterNameType } from "../types";

export type ParameterUIType = {
    parameterName: ProjectionParameterNameType,
    forecastParameter: ForecastParameterType,
}

type UiSliceType = {
    requestPending: boolean,
    chosenParameter?: ParameterUIType,
    drawerOpen: boolean,
    dialogOpen: boolean,
}

export const uiSlice = createSlice({
    name: "uiSlice",
    initialState: {
        requestPending: false,
        chosenParameter: undefined,
        drawerOpen: false,
        dialogOpen: false,
    } as UiSliceType,
    reducers: {
        requestPending: (state, action) => {
            state.requestPending = action.payload
        },
        chooseParameter: (state, { payload }:PayloadAction<ParameterUIType | undefined>) => {
            state.chosenParameter = payload
        },
        chooseParameterMethod: (state, { payload }:PayloadAction<ForecastParameterType>) => {
            if (state.chosenParameter)
                state.chosenParameter = {
                    ...state.chosenParameter,
                    forecastParameter: payload,
                }
        },
        openDrawer: (state) => {
            state.drawerOpen = true
        },
        closeDrawer: (state) => {
            state.drawerOpen = false
        },
        openDialog: (state) => {
            state.dialogOpen = true
        },
        closeDialog: (state) => {
            state.dialogOpen = false
        },
    }
})

export const selectDrawerOpen = (state: RootState) => state.uiSlice.drawerOpen
export const selectDialogOpen = (state: RootState) => state.uiSlice.dialogOpen
export const selectChosenParameter = (state: RootState) => state.uiSlice.chosenParameter

export const {
    chooseParameter,
    requestPending,
    chooseParameterMethod,
    openDrawer,
    closeDrawer,
    openDialog,
    closeDialog,
} = uiSlice.actions
