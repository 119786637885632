import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ParameterMethodNameType } from "../types"

type ChooseProjectionMethodViewType = {
    method: ParameterMethodNameType,
    supportedMethods: string[],
    changeMethod: (method: ParameterMethodNameType) => void,
}

const ChooseProjectionMethodView = ({ changeMethod, method, supportedMethods }: ChooseProjectionMethodViewType) => {

    const { t } = useTranslation()

    return (
        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{t('forecastMethod.method')}</FormLabel>
            <RadioGroup
                onChange={(e) => changeMethod(e.target.value as ParameterMethodNameType)}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={method}
            >
                {
                    supportedMethods.map((method, idx) =>
                        <FormControlLabel key={idx} value={method} control={<Radio />} label={t(`forecastMethod.${method}`)} />
                    )
                }
            </RadioGroup>
        </FormControl>
    )
}

export default ChooseProjectionMethodView