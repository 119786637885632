
const MainAdminPage = () => {
    return (
        <div className="App">
            Kalvo 3
            {/* <img style={{ float: 'left' }} src="./logo.png" alt="logo" /> */}
            {/* <Outlet />
            <MunicipalityList />
            <SearchMunicipalityForm />
            <MunicipalityForm /> */}
        </div>
    )
}

export default MainAdminPage