import { Stack, Tab, Tabs } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useMemo, useState } from "react"
import { useAppSelector } from "../../app/hooks"
import { countPopulationChange, sumCohorts } from "../../common/functions"
import { TableView } from "../../components/TableView"
import { PopulationChangeChart, StackedPopulationChart } from "../../components/charts/ComposedCharts"
import { MunicipalForecastSummaryType } from "../../types"
import { selectCurrentForecast, useGetForecastMunicipalSummaryDataQuery } from "../apis/forecastApi"
import { AgeGroupDiagram } from "./ChangeBarChart"

export const ProjectionView = () => {

    const forecast = useAppSelector(selectCurrentForecast)

    const { hook, parameters } = useMemo(() => {
        return { hook: useGetForecastMunicipalSummaryDataQuery, parameters: { forecastId: forecast!.id } }
    }, [ forecast ]) as any

    const { stackedData, composedData, tableData } = hook(parameters, {
        selectFromResult: ({ data }:{ data: MunicipalForecastSummaryType }) => ({
            composedData: countPopulationChange(
                Object.entries(data?.aggregated || {})
                    .map(([ year, sum ]) => ({ year: Number(year), total: sum.sum })))
                    .reduce((prev, curr) => prev.concat(curr), [] as any
                ),
            stackedData: Object.entries(data?.change || {}).map(( [ year, item ]) => {

                const [ migration, interMunicipalMigration, naturalChange ] = [
                    sumCohorts(item.immigration) - sumCohorts(item.emigration),
                    sumCohorts(item.interMunicipalMigrationIn) - sumCohorts(item.interMunicipalMigrationOut),
                    sumCohorts(item.births) - sumCohorts(item.deaths)
                ]
                
                return {
                    year,
                    migration,
                    interMunicipalMigration,
                    naturalChange,
                    total: migration + interMunicipalMigration + naturalChange,
            }}),
            tableData: Object.entries(data?.change ?? {}).map(([ year, row ]) => ({
                year,
                emigration: sumCohorts(row.emigration),
                immigration: sumCohorts(row.immigration),
                interMunicipalMigrationIn: sumCohorts(row.interMunicipalMigrationIn),
                interMunicipalMigrationOut: sumCohorts(row.interMunicipalMigrationOut),
            }))
        })
    })

    const [tab, setTab] = useState<number>(0)
    const changeTab = (_: React.SyntheticEvent, tabIndex: number) => {
        setTab(tabIndex)
    }

    return (
        <>
            <Tabs value={tab} onChange={changeTab}>
                <Tab icon={<span className="material-icons-outlined">equalizer</span>} />
                <Tab icon={<span className="material-icons-outlined">table_chart</span>} />
            </Tabs>
            {tab === 0 ? (
                <Grid2 container xs={12} spacing={2} width={'100%'} height={'100%'}>
                    <Grid2 xs={7} height={'100%'}>
                        <Stack>
                            <PopulationChangeChart
                                data={composedData}
                            />
                            <StackedPopulationChart
                                data={stackedData || []}
                            />
                        </Stack>
                    </Grid2>
                    <Grid2 xs={5}>
                        <AgeGroupDiagram />
                    </Grid2>
                </Grid2>
            ) : (
                (tableData && <TableView data={tableData} />) || null
            )
            }
        </>
    )
}
