import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { closeDialog, selectDialogOpen } from '../common/uiSlice';

export type AlertDialogProps = {
    title: string,
    message: string,
    onOk?: () => void,
    onCancel?: () => void,
}
const AlertDialog = ({ title, message, onOk, onCancel }:AlertDialogProps) => {

    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    const open = useAppSelector(selectDialogOpen)

    const handleClose = () => {
        dispatch(closeDialog())
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { onCancel && onCancel(); handleClose() }} autoFocus>{t('general.cancel')}</Button>
                    <Button onClick={() => { onOk && onOk(); handleClose() }}>
                        {t('general.apply')}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default AlertDialog