import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import './App.css';
import { SignInButton } from './app/SignInButton';
import AuthenticatedApp from './features/AuthenticatedApp';

const App = () => (
  <>
    <AuthenticatedTemplate>
      <AuthenticatedApp />
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate><SignInButton /></UnauthenticatedTemplate>
  </>
)

export default App;
