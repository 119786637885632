import { Box, FormGroup, FormLabel, Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';

const HistoryChip = ({ year, chosen, onClick }: { year: number, onClick: (year:number) => void, chosen: boolean }) => (
    <Chip clickable label={year} onClick={() => onClick(year)} color={'primary'} variant={chosen ? 'filled' : 'outlined'} />
)

type ChooseHistoryYearsProps = {
    setHistoryYears: (years: number[]) => void,
    currentHistoryYears: number[],
    availableHistoryYears?: number[],
}
const ChooseHistoryYearsView = ({ setHistoryYears, currentHistoryYears, availableHistoryYears }: ChooseHistoryYearsProps) => {

    const { t } = useTranslation()

    const handleClick = (year:number) => {
        const remove = currentHistoryYears.includes(year)
        if (remove && currentHistoryYears.length < 2) return
        setHistoryYears(remove ? currentHistoryYears.filter(y => y !== year) : [ ...currentHistoryYears, year ])
    }

    return (
        availableHistoryYears && currentHistoryYears ?
        <Box paddingTop={'1rem'} paddingBottom={'1rem'}>
            <FormGroup>
                <FormLabel>{t('forecast.historyYears')}</FormLabel>
                <Stack direction="row" spacing={1}>
                    {availableHistoryYears.map((year, idx) => (
                        <HistoryChip key={idx} year={year} onClick={handleClick} chosen={currentHistoryYears.includes(year)} />
                    ))}
                </Stack>
            </FormGroup>
        </Box>
        : null
    );
}

export default ChooseHistoryYearsView