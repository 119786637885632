import { darken, lighten, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGridPremium, DataGridPremiumProps, fiFI } from '@mui/x-data-grid-premium';

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);


const StyledDataGrid = styled(DataGridPremium)(({ theme }) => {
  return {
    '& .super-app-theme--FirstRow': {
      backgroundColor: getBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
          ),
        },
      },
      fontWeight: 700,
      fontSize: 1.2 + 'rem',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: ' none'
    },
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer'
    },
    '& .super-app-theme--BoldRow': {
      fontWeight: 700,
    },
    fiFI
  }
})

export const DataGridComponent = (props: DataGridPremiumProps) => {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <StyledDataGrid
        sx={{ ...props.sx, position: 'absolute', width: '100%' }}
        {...props}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
          ...props.initialState,
        }}
        pageSizeOptions={[20]}
        density='compact'
      />
    </Box>
  );
}
