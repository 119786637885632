import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar, ComposedChart, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useAppSelector } from '../../app/hooks'
import { useGetAdjustedSummaryQuery } from '../apis/apiSlice'
import { selectCurrentForecastId } from "../apis/appSlice"

const AdjustmentView = () => {

    const { t } = useTranslation()

    const { id: forecastId } = useAppSelector(selectCurrentForecastId)!


    const { data } = useGetAdjustedSummaryQuery({ forecastId })

    const adjustmentData = useMemo(
        () => Object.entries(data?.unadjusted_sums || {})
            .map(([year, adjustments]) => {

                const { projections, target: areaForecast } = adjustments
                const [existingBuildings, newBuildings] = projections

                return {
                    year,
                    existingBuildings,
                    newBuildings,
                    areaForecast,
                }
            }
            ),
        [data]
    )

    const tooltipFormatter = (value: number, name: string | number) => [ Math.round(value), t(`charts.${name}`) ]
    const legendFormatter = (value: any) => t(`charts.${value}`)

    return (
        <div>
            <ResponsiveContainer aspect={1.85} width={'100%'}>
                <ComposedChart width={730} height={250} data={adjustmentData} stackOffset='sign'>
                    <XAxis dataKey='year' />
                    <YAxis />

                    <Tooltip formatter={tooltipFormatter} />
                    <Legend formatter={legendFormatter} />

                    <ReferenceLine y={0} stroke='#000' />
                    <Bar dataKey={'existingBuildings'} stackId='1' fill='#70C4F2' stroke='#70C4F2' />
                    <Bar dataKey={'newBuildings'} stackId='1' fill='#C4E8FA' stroke='#C4E8FA' />

                    <Line type='monotone' dataKey='areaForecast' stroke='#000000' strokeWidth={4} dot={false} />

                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )
}

export default AdjustmentView