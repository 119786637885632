import { Box, Button, Chip, Select, Stack, Typography } from "@mui/material"
import { t } from "i18next"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { AREA_PROJECTION_PARAMETERS } from "../../common/constants"
import { closeDrawer } from "../../common/uiSlice"
import { ScenarioChangeInput, ScenarioChangeTypeInput, ScenarioNameInput, ScenarioParameterSlider } from "../../components/ScenarioFormComponents"
import { ChangeType, ProjectionParameterNameType } from "../../types"
import { selectCurrentForecast, useUpdateForecastChangesMutation } from "../apis/forecastApi"

export type AreaCodeNameType = {
    areaCode: string,
    areaName: string,
}

type ScenarioAreaInputPropsType = {
    areas: AreaCodeNameType[],
    onDeleteArea: (areaCode: string) => void,
}

export const ScenarioAreaInput = ({ areas, onDeleteArea }: ScenarioAreaInputPropsType) => {
    return (
        <Box padding={'0.5rem'}>
            <Box>
                <div><b>{t('general.affectedAreas')}</b></div>
                <span>{t('general.clickTableToChoose')}</span>
            </Box>
            <Box paddingLeft={'7rem'}>
                <Box sx={{ overflow: "scroll", height: '7rem' }}>
                    {
                        areas.map(({ areaCode, areaName }) => (
                            <Chip key={areaCode} label={areaName} onDelete={() => onDeleteArea(areaCode)} />
                        ))
                    }
                </Box>
            </Box>
        </Box>
    )
}

type ScenarioParameterInputPropsType = {
    parameters: string[],
    setParameters: (parameters: string[]) => void,
}

export const ScenarioParameterInput = ({ parameters, setParameters }: ScenarioParameterInputPropsType) => {

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { options } = event.target        
        const selected = Array.from(options).filter(option => option.selected).map(option => option.value)
        setParameters(selected)
    }

    return (
        <Box padding={'0.5rem'}>
            <Box>
                <div><b>{t('general.affectedParameters')}</b></div>
            </Box>
            <Box paddingLeft={'7rem'}>
                <Select
                    multiple
                    native
                    // @ts-ignore
                    onChange={handleChange}
                    value={parameters as any}
                    size="small"
                >
                    {
                        AREA_PROJECTION_PARAMETERS.map((parameter) =>
                            <option key={parameter.id} value={parameter.id}>{t(`projectionParameter.${parameter.id}`)}</option>
                        )
                    }
                </Select>
            </Box>
        </Box>
    )
}

type EditAreaParametersProps = ScenarioAreaInputPropsType & {
    onClose: () => void,
}

const EditAreaParameters = ({ areas, onDeleteArea, onClose }: EditAreaParametersProps) => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const forecast = useAppSelector(selectCurrentForecast)

    const { fromYear, toYear } = forecast!
    const [triggerUpdate] = useUpdateForecastChangesMutation()

    const changeId = !(forecast?.changes || []).length ? 1 : Math.max(...forecast!.changes.map((change) => change.id)) + 1

    const closeView = () => {
        dispatch(closeDrawer())
        onClose()
    }

    const submit = (event: any) => {
        event.preventDefault()
        triggerUpdate({ forecastId: forecast!.id!, changes: [ change ] })
        closeView()
    }

    const [change, setChange] = useState<ChangeType>({
        id: changeId,
        name: '',
        affectedAreas: areas.map(({ areaCode }) => areaCode),
        affectedParameters: [],
        fromYear,
        toYear,
        fromAge: 0,
        toAge: 99,
        changeType: 'absolute',
        change: 0,
    })

    const setYearValues = (values: number[]) => {
        const [fromYear, toYear] = values
        setChange({ ...change, fromYear, toYear })
    }

    const setAgeValues = (values: number[]) => {
        const [fromAge, toAge] = values
        setChange({ ...change, fromAge, toAge })
    }

    const setName = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = target
        setChange({ ...change, name: value })
    }

    const setChangeValue = (value: number) => {
        setChange({ ...change, change: value })
    }

    const setChangeType = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = target
        setChange({ ...change, changeType: value as 'absolute' | 'percentage' })
    }

    const setParameterValues = (parameters: string[]) => {
        setChange({ ...change, affectedParameters: parameters as ProjectionParameterNameType[] })
    }

    return (
        <Stack>
            <Typography variant='h5'>
                {t('general.editParameter')}
            </Typography>
            <form>
                <Stack sx={{ margin: '1rem' }}>
                    <ScenarioNameInput value={change.name} onChange={setName} />
                    <ScenarioParameterInput parameters={change.affectedParameters} setParameters={setParameterValues} />
                    <ScenarioAreaInput areas={areas} onDeleteArea={onDeleteArea} />
                    <ScenarioParameterSlider title={t('general.years')} min={fromYear} max={toYear} value={[change.fromYear, change.toYear]} onChange={(_, value) => setYearValues(value as number[])} />
                    <ScenarioParameterSlider title={t('general.ages')} min={0} max={99} value={[change.fromAge, change.toAge]} onChange={(e, value) => setAgeValues(value as number[])} />
                    <ScenarioChangeTypeInput value={change.changeType} onChange={setChangeType} />
                    <ScenarioChangeInput onChange={setChangeValue} relative={change.changeType === 'percentage'} />
                </Stack>
                <Stack direction="row" spacing={1} width='100%' alignItems={'right'} padding={'0.5rem'}>
                    <Button variant="outlined" type="reset" onClick={() => closeView()}>{t('general.cancel')}</Button>
                    <Button variant="contained" type="submit" onClick={submit}>{t('general.apply')}</Button>
                </Stack>
            </form>
        </Stack>
    )
}

export default EditAreaParameters