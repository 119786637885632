import { Box, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../app/hooks";
import { selectForecastById } from "../features/apis/forecastApi";
import { ForecastType } from "../types";

// type PopoverContentForScenarioProps = {
//     scenario: ScenarioType,
// }

// export const PopoverContentForScenario = ({ scenario }: PopoverContentForScenarioProps) => {

//     const { t } = useTranslation();

//     const { id } = scenario;

//     // const { data } = useGetScenarioQuery({ scenarioId: id! });
//     const data = useAppSelector((state) => selectScenarioById(state, id!))

//     return (
//         <Box sx={{ padding: '1rem' }}>
//             <Typography variant="h5">{scenario.name}</Typography>
//             <Typography variant="h6">{scenario.description}</Typography>
//             <List>
//                 {(data?.changes || []).map((c, idx) => {

//                     const { name, change, changeType, affectedParameters } = c;
//                     const valueString = `${change < 0 ? '-' : '+'}${change}${changeType === 'percentage' ? '%' : ''}`;
//                     const parameterStr = t(`projectionParameter.${affectedParameters[0]}`);

//                     return (
//                         <ListItem key={idx}>
//                             <ListItemAvatar>
//                                 <span className="material-icons-outlined">
//                                     published_with_changes
//                                 </span>
//                             </ListItemAvatar>
//                             <ListItemText
//                                 primary={name}
//                                 secondary={`${parameterStr} ${valueString}`}
//                             >
//                             </ListItemText>
//                             <Divider variant="inset" component="hr" />
//                         </ListItem>
//                     );
//                 })}
//             </List>
//         </Box>
//     );
// };

type PopoverContentForForecastProps = {
    forecast: ForecastType,
}

export const PopoverContentForForecast = ({ forecast }: PopoverContentForForecastProps) => {

    const { t } = useTranslation();

    const { id: forecastId } = forecast
    const data = useAppSelector((state) => selectForecastById(state, forecastId!))

    return (
        <Box sx={{ padding: '1rem' }}>
            <Typography variant="h5">{forecast.name}</Typography>
            <Typography variant="h6">{forecast.description}</Typography>
            <List>
                {(Object.entries(data?.municipalMethods || {}) || []).map(([parameterName, parameterValue], idx) => {

                    const parameterValueString = t(`forecastMethod.${parameterValue.method}`);
                    const parameterNameStr = t(`projectionParameter.${parameterName}`);

                    return (
                        <ListItem key={idx}>
                            <ListItemAvatar>
                                <span className="material-icons-outlined">
                                    published_with_changes
                                </span>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<><Typography fontWeight={700}>{parameterNameStr}</Typography><Typography>{parameterValueString}</Typography></>}
                            >
                            </ListItemText>
                            <Divider variant="inset" component="hr" />
                        </ListItem>
                    );
                })}
            </List>
            <List>
                 {(data?.changes || []).map((c, idx) => {

                     const { name, change, changeType, affectedParameters } = c;
                     const valueString = `${change < 0 ? '-' : '+'}${change}${changeType === 'percentage' ? '%' : ''}`;
                     const parameterStr = t(`projectionParameter.${affectedParameters[0]}`);

                     return (
                         <ListItem key={idx}>
                             <ListItemAvatar>
                                 <span className="material-icons-outlined">
                                     published_with_changes
                                 </span>
                             </ListItemAvatar>
                             <ListItemText
                                 primary={name}
                                 secondary={`${parameterStr} ${valueString}`}
                             >
                             </ListItemText>
                             <Divider variant="inset" component="hr" />
                         </ListItem>
                     );
                 })}
             </List>

        </Box>
    );
};
