import { Tab, Tabs, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import DrawerComponent from "../../components/Drawer";
import { selectCurrentForecast } from "../apis/forecastApi";
import EditForecastParameterView from "./EditForecastParameterView";
import EditMunicipalParameters from "./EditMunicipalParameters";
import { ProjectionView } from "./ProjectionView";
import { SparkLines } from "./SparkLines";

const MemoizedSparkLines = memo(SparkLines)

export const MunicipalityView = () => {

    const { t } = useTranslation()
    const forecast = useAppSelector(selectCurrentForecast)

    const [tab, setTab] = useState<number>(0)
    const changeTab = (_: React.SyntheticEvent, tabIndex: number) => {
        setTab(tabIndex)
    }

    return (
        <>
            <Grid2 container xs={12} spacing={2} width={'100%'} height={'100%'}>
                <Grid2 xs={2} height={'100%'}>
                    <Typography variant='h3' style={{ fontSize: '1rem', fontWeight: 700 }}>{t('projectionParameter.municipalityLevelParameters')}</Typography>
                    <MemoizedSparkLines />
                </Grid2>
                <Grid2 xs={10}>
                    <Typography variant='h3' style={{ fontSize: '1rem', fontWeight: 700 }}>{t('projectionParameter.basicScenarioProjection')}</Typography>
                    <ProjectionView />
                </Grid2>
            </Grid2>
            <DrawerComponent>
                <Tabs value={tab} onChange={changeTab}>
                    <Tab label={t('projectionParameter.trend')} />
                    <Tab label={t('projectionParameter.scenarioChanges')} />
                </Tabs>
                { tab === 0 ? <EditForecastParameterView /> : <EditMunicipalParameters /> }
            </DrawerComponent>
        </>
    )
}